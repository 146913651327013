import { UrlUtils } from './shared/url-utils';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MissingTranslationHandler, TranslateService, } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from './shared/translation-helper';
/**
 * Load a file with translations for the current user's language.
 * @param http
 * @param route
 */
export function createTranslateLoader(http, route) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
/**
 * Add square brackets around strings that don't have translations.
 */
var MyMissingTranslationHandler = (function () {
    function MyMissingTranslationHandler() {
    }
    MyMissingTranslationHandler.prototype.handle = function (params) {
        return '[' + params.key + ']';
    };
    return MyMissingTranslationHandler;
}());
export { MyMissingTranslationHandler };
/**
 * Initial data load
 * @param injector
 */
export function requestInitialAppData(injector) {
    return function () { return Promise.all([
        new Promise(function (resolve) {
            var locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(function () {
                var translate = injector.get(TranslateService);
                setLang(translate, resolve);
            });
        })
    ]); };
}
/**
 * Our app. Here the main modules will be imported that we need to render the header and the footer.
 *
 * It is here we setup the initial loader which fires before the app loads. This is to load the translations befor the app starts.
 */
export function setLang(translate, resolve) {
    var langToSet;
    if (UrlUtils.getLang()) {
        langToSet = UrlUtils.getLang();
    }
    else {
        langToSet = translate.getBrowserCultureLang();
        langToSet = (langToSet !== undefined && langToSet !== null && langToSet !== '') ? langToSet.substring(0, 2) : DEFAULT_LANGUAGE;
        langToSet = SUPPORTED_LANGUAGES.some(function (o) { return o.indexOf(langToSet) >= 0; }) ? langToSet : DEFAULT_LANGUAGE;
    }
    translate.setDefaultLang(langToSet);
    translate.use(langToSet)
        .subscribe(function () {
    }, function (err) {
        console.error("Problem with '" + langToSet + "' language initialization.'");
    }, function () {
        resolve(null);
    });
}
var ɵ0 = (createTranslateLoader);
var AppModule = (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
