import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApxInputComponent } from '@apirix/ng-apx';
import { TranslateService } from '@ngx-translate/core';
import { ToastsManager } from 'ng2-toastr/src/toast-manager';
import { TranslationHelper } from '../shared/translation-helper';
import { UrlUtils } from '../shared/url-utils';
import { logError, getCookie } from '../shared/utils';
import { ValidationHelper } from '../shared/validation-helper';
import { Login } from './login';
import { LoginService } from './login.service';
import { environment } from '../../environments/environment';
import { RegexUtils } from 'app/shared/regex-utils';

/**
 * Here the user can login
 */
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

	validationHelper: ValidationHelper;
	login: Login;

	loading = false;
	shouldRender = false;
	showHelp = false;
	showError = false;
	showCodeError = false;
	showRequestWentWrongError = false;
	useOneTimeCode = false;
	canRequestCode = true;

	secondsLeft = 10;

	@ViewChild(ApxInputComponent) firstInput: ApxInputComponent;
	@ViewChild('useOneTimeCodeInput') useOneTimeCodeInput: ApxInputComponent;
	@ViewChildren(ApxInputComponent) formControls: ApxInputComponent[];

	constructor(
		private translateService: TranslateService,
		private loginService: LoginService,
		private toastr: ToastsManager,
		private router: Router,
		private cdRef: ChangeDetectorRef) {
		this.validationHelper = new ValidationHelper(this.toastr);
	}

	ngOnInit() {
		if (this.isLoggedIn()) {
			UrlUtils.navigate(environment.defaultRedirect);
		} else {
			this.shouldRender = true;
		}

		this.login = new Login(
			new TranslationHelper(this.translateService).translatedHelpText(),
			this.translateService
		);

		// Toggle the first input in login form
		if (this.firstInput) {
			this.firstInput.cdRef.markForCheck();
			this.cdRef.detectChanges();
			if (this.firstInput.input && this.firstInput.input.nativeElement) {
				this.firstInput.toggleFocus(true);
				this.firstInput.input.nativeElement.focus();
				this.firstInput.cdRef.markForCheck();
			}
		}

		if (this.useOneTimeCodeInput) {
			this.useOneTimeCodeInput.settings.required = this.useOneTimeCode;
		}
	}

	isLoggedIn() {
		return !!getCookie('idsrv.session');
	}

	/**
	 * Toggles help panel
	 */
	toggleHelp() {
		this.showHelp = !this.showHelp;
	}

	/**
	 * Authenticates a user by passing email and password
	 */
	async authenticate(form: NgForm) {
		this.cdRef.detectChanges();

		if (this.isLoggedIn()) {
			this.shouldRender = false;
			UrlUtils.navigate(environment.defaultRedirect);
		}

		if (this.validationHelper.validateForm(form, true, this.formControls)) {
			this.showError = false;
			this.showCodeError = false;
			this.loading = true;

			let redirectUrl = UrlUtils.getParam('redirect_uri', true);
			if (redirectUrl === '' || redirectUrl !== '') { // Always use default redirect for now. We will set and get from storage, see AIWC-333.
				redirectUrl = environment.defaultRedirect;
			}

			if (this.useOneTimeCode) {
				try {
					await this.loginService.signInUsingSingleCode(this.login.email, this.login.oneTimeCode);
					UrlUtils.navigate(redirectUrl);

				} catch (errorReponse) {
					if (errorReponse.status === 403) {
						this.router.navigateByUrl('/lockedout');
					} else if (errorReponse.status === 401) {
						this.showCodeError = true;
						this.loading = false;
					} else {
						this.showCodeError = true;
						this.loading = false;
						logError(errorReponse);
					}
				}

			} else {
				try {
					const response = await this.loginService.authenticate(this.login.email, this.login.password, this.translateService.currentLang)
					if (response) {
						const latestBustedVersion = localStorage.getItem("latest-busted-version");
						const versionToBust = "1.28.3";

						if (latestBustedVersion !== versionToBust) {

							await fetch("https://admin.inquinova.com/callback", {
								method: "GET",
								cache: "no-cache",
							}).catch(e => console.error(e));

							localStorage.setItem("latest-busted-version", versionToBust);
						}

						UrlUtils.navigate(redirectUrl);
					} else {
						this.showError = true;
						this.loading = false;
					}
				} catch (err) {
					if (err.status === 403) {
						this.router.navigateByUrl('/lockedout');
					} else if (err.status === 401) {
						this.showError = true;
						this.loading = false;
					} else {
						this.showError = true;
						this.loading = false;
						logError(err);
					}
				}
			}
		}
	}

	useCode() {
		this.showError = false;
		this.showCodeError = false;
		this.firstInput.settings.validation.submitted = true;
		this.cdRef.detectChanges();
		if (this.firstInput.settings.validation.valid) {
			this.useOneTimeCode = true;
			this.requestCode();
		}
	}

	goBack() {
		this.login.oneTimeCodeSettings.required = false;

		setTimeout(() => {
			this.login.oneTimeCode = null;
			this.useOneTimeCode = false;
		})
	}

	async requestCode() {

		if (this.canRequestCode) {

			try {
				this.loading = true;
				await this.loginService.requestOneTimeCode(this.login.email)
				this.loading = false
				this.showRequestWentWrongError = false
				this.canRequestCode = false;

				let timer = setInterval(() => {
					if (this.secondsLeft <= 0) {
						clearInterval(timer);
						this.canRequestCode = true;
						this.secondsLeft = 10;
					}
					this.secondsLeft -= 1;
				}, 1000);

			} catch (errorResponse) {
				this.showRequestWentWrongError = true;
				this.loading = false;
			}
		}
	}


	getRedirectText() {
		return `${this.translateService.instant('RedirectText')} ${environment.defaultRedirect}`;
	}
}
