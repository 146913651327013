import { UrlUtils } from './shared/url-utils';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
	MissingTranslationHandler,
	MissingTranslationHandlerParams,
	TranslateLoader,
	TranslateModule,
	TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastModule, ToastOptions } from 'ng2-toastr';
import { PopoverModule } from 'ngx-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordService } from './forgot-password/forgot-password.service';
import { LockedoutComponent } from './lockedout/lockedout.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { LogoutComponent } from './logout/logout.component';
import { LogoutService } from './logout/logout.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordService } from './reset-password/reset-password.service';
import { HttpClientService } from './shared/httpclient.service';
import { SharedModule } from './shared/shared.module';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from './shared/translation-helper';


/**
 * Load a file with translations for the current user's language.
 * @param http
 * @param route
 */
export function createTranslateLoader(http: HttpClient, route: ActivatedRoute) {
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

/**
 * Add square brackets around strings that don't have translations.
 */
export class MyMissingTranslationHandler implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams) {
		return '[' + params.key + ']';
	}
}

/**
 * Initial data load
 * @param injector
 */
export function requestInitialAppData(injector: Injector) {
	return () => Promise.all([
		new Promise<any>((resolve: any) => {
			const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
			locationInitialized.then(() => {
				const translate = injector.get(TranslateService);
				setLang(translate, resolve);
			});
		})
	]);
}

/**
 * Our app. Here the main modules will be imported that we need to render the header and the footer.
 *
 * It is here we setup the initial loader which fires before the app loads. This is to load the translations befor the app starts.
 */
export function setLang(translate: TranslateService, resolve?: any) {
	let langToSet;
	if (UrlUtils.getLang()) {
		langToSet = UrlUtils.getLang();
	} else {
		langToSet = translate.getBrowserCultureLang();
		langToSet = (langToSet !== undefined && langToSet !== null && langToSet !== '') ? langToSet.substring(0, 2) : DEFAULT_LANGUAGE;
		langToSet = SUPPORTED_LANGUAGES.some(o => o.indexOf(langToSet) >= 0) ? langToSet : DEFAULT_LANGUAGE;
	}

	translate.setDefaultLang(langToSet);
	translate.use(langToSet)
		.subscribe(() => {
		}, err => {
			console.error(`Problem with '${langToSet}' language initialization.'`);
		}, () => {
			resolve(null);
		});
}

@NgModule({
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ToastModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient, ActivatedRoute]
			}
		}),
		SharedModule,
		HttpClientModule,
		PopoverModule.forRoot(),
		//ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true })
	],
	declarations: [
		AppComponent,
		LoginComponent,
		ForgotPasswordComponent,
		LogoutComponent,
		LockedoutComponent,
		PageNotFoundComponent,
		ResetPasswordComponent
	],
	providers: [
		HttpClientService,
		LoginService,
		ForgotPasswordService,
		ResetPasswordService,
		{
			provide: APP_INITIALIZER,
			useFactory: requestInitialAppData,
			deps: [Injector],
			multi: true
		},
		{
			provide: MissingTranslationHandler,
			useClass: MyMissingTranslationHandler
		},
		LogoutService,
		ToastOptions
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
