import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClientService } from '../shared/httpclient.service';
import { UrlUtils } from '../shared/url-utils';

export const LOGOUT_ENDPOINT = '/api/account/logout';

export const ENDPOINTS = {
	AUTH: `${LOGOUT_ENDPOINT}`,
};

/**
 * Calls the api for logout logic
 */
@Injectable()
export class LogoutService {
	constructor(private httpClientService: HttpClientService) { }

	public logout() {
		return this.httpClientService
			.post(environment.IdsUrl + ENDPOINTS.AUTH, {
				logoutId: UrlUtils.getParam('logoutId')
			});
	}
}
