import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from './translation-helper';
import { URLSearchParams } from '@angular/http';
import { Headers } from '@angular/http';
import { environment } from '../../environments/environment';

/**
 * Utility methods to help resolve URLs.
 */
export class UrlUtils {

	/**
	 * Headers needed to make a request against the IdentityServer.Api
	 */
	public static httpHeaders = new Headers({
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	});

	/**
	 * Gets url param
	 * @param param
	 */
	public static getParam(param: string, decodeUrl: boolean = true) {
		const url = decodeUrl ? decodeURIComponent(window.location.href) : window.location.href;
		param = param.replace(/[\[\]]/g, '\\$&');
		const regex = new RegExp('[?&]' + param + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) {
			return null;
		}
		if (!results[2]) {
			return '';
		}
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	/**
	 * Gets the language thats active
	 */
	public static getLang(): string | boolean {
		const urlSearchParams = new URLSearchParams(decodeURIComponent(window.location.href));
		const locale = urlSearchParams.get('ui_locales');
		const storageLocale = localStorage.getItem('i18n');
		if (storageLocale && SUPPORTED_LANGUAGES.some(o => o.indexOf(storageLocale) >= 0)) {
			return storageLocale;
		} else if (locale && SUPPORTED_LANGUAGES.some(o => o.indexOf(locale) >= 0)) {
			return locale;
		} else {
			return false;
		}
	}

	// Functions below are not testable, they are wrappers for the window.location to make other tests possible

	/**
	 * Reloads the page
	 */
	public static reload() {
		window.location.reload();
	}

	/**
	 * Navigates to a url
	 * @param target
	 */
	public static navigate(target: string) {
		window.location.href = target;
	}
}
