<div class="page">
	<div class="message" *ngIf="showMessage">
		{{message}}
	</div>

	<header class="page__header">
		<a routerLink="/login" title="Inquinova Platform" class="link">
			<div class="logotype" [style.background-image]="'url(' + headerLogo + ')'"></div>
		</a>
	</header>

	<section class="page__container" [ngClass]="{'not-visible': !ready}">
		<router-outlet></router-outlet>
	</section>
	<ng-container *ngIf="!ready">
			<div class="loader">
				<div class="dot dot-bounce1"></div>
				<div class="dot dot-bounce2"></div>
				<div class="dot dot-bounce3"></div>
			</div>
		</ng-container>

	<footer class="page__footer">
		<ul class="languages">
			<li class="languages__language">
				<a class="link" (click)="changeLanguage('sv')" title="Svenska">
					<img width="20" src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.3.1/flags/4x3/se.svg" alt="" class="flag-icon" />
				</a>
			</li>
			<li class="languages__language">
				<a class="link" (click)="changeLanguage('en')" title="English">
					<img width="20" src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.3.1/flags/4x3/gb.svg" alt="" class="flag-icon" />
				</a>
			</li>
			<li class="languages__language">
				<a class="link" (click)="changeLanguage('no')" title="Norsk">
					<img width="20" src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.3.1/flags/4x3/no.svg" alt="" class="flag-icon" />
				</a>
			</li>
		</ul>
		<p>©2009-{{ currentYear }} Inquinova AB. {{ 'AllRightsReserved' | translate }}.</p>
	</footer>
</div>