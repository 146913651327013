import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LogoutService } from './logout.service';
import { logError } from '../shared/utils';
import { UrlUtils } from '../shared/url-utils';

/**
 * An component that logouts a user and then navigates to login on success
 */
@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.less']
})
export class LogoutComponent implements OnInit {

	constructor(private logoutService: LogoutService, private router: Router) { }

	ngOnInit() {
		this.logoutService.logout().then(result => {
			if (result !== 'success') {
				logError(result);
			}
			this.router.navigate(['login']);
		});
	}

}
