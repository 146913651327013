<div class="row">
    <div class="panel col-xs-12 col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3">
      <header class="panel__header">
        <h1>{{'LockedOut' | translate}}</h1>
	  </header>
	  <section class="panel__body text-center">
			<p>{{'LockedOutInstructions' | translate}} </p>
	  </section>
      <footer class="panel__footer">
          <a routerLink="/login" type="button" class="btn btn-transparent">{{'Back' | translate}}</a>
      </footer>
    </div>
  </div>