import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { ApxInputComponent, IconSettings, StringUtils } from '@apirix/ng-apx';
import { NgForm } from '@angular/forms';

export const VALIDATION_STRINGS = {
	SERVER_ERRORS: 'serverErrors',
	SETTINGS_PROP: 'Settings'
};

/**
 * Reusable helpers to validate all sorts of things.
 */
export class ValidationHelper {
	constructor(private toastr: ToastsManager) { }

	validateForm(form: NgForm, setSubmittedFlag: boolean, inputs?: ApxInputComponent[]) {
		let valid = form.valid;
		inputs.forEach(o => {
			if (setSubmittedFlag) {
				o.settings.validation.submitted = true;
			}
			// trigger validation check
			o.hideErrors(o.inputControl);
			if (!o.settings.validation.valid) {
				valid = false;
			}
		});
		return valid;
	}

	/**
	 * Clears validation on all ApxInputs
	 * @param inputs
	 */
	clearValidation(inputs: ApxInputComponent[]) {
		inputs.forEach(o => {
			o.settings.validation.submitted = false;
			o.settings.validation.valid = false;
			o.settings.validation.touched = false;
		});
	}

	/**
	 * Clears server errors on all ApxInputs
	 * @param inputs
	 */
	clearServerValidation(inputs: ApxInputComponent[]) {
		inputs.forEach(o => {
			o.settings.serverErrors = [];
		});
	}
}
