import { environment } from '../../environments/environment';

/**
 * Methods that helps with validation
 */
export function logError(message?: any, ...optionalParams: any[]) {
	if (environment.production) {
		console.error(message, optionalParams);
	}
}

/**
 * Methods that helps with validation
 */
export function logWarning(message?: any, ...optionalParams: any[]) {
	if (environment.production) {
		console.warn(message, optionalParams);
	}
}

export function getFullCulture(culture: string) {
	switch (culture) {
		case 'sv':
			return 'sv-SE';
		case 'en':
			return 'en-US';
		case 'no':
			return 'nb-NO';
		default:
			return 'sv-SE';
	}
}

// Given a cookie key `name`, returns the value of
// the cookie or `null`, if the key is not found.
export function getCookie(name: string): string {
	const nameLenPlus = (name.length + 1);
	return document.cookie
		.split(';')
		.map(c => c.trim())
		.filter(cookie => {
			return cookie.substring(0, nameLenPlus) === `${name}=`;
		})
		.map(cookie => {
			return decodeURIComponent(cookie.substring(nameLenPlus));
		})[0] || null;
}
