<div class="row">
	<div class="col-xs-12 col-sm-8 col-md-6 col-lg-4 col-sm-offset-2 col-md-offset-3 col-lg-offset-4">
		<a routerLink="/login" title="{{'Go to the login page' | translate}}" class="login-link">
			{{ "SignIn" | translate }}
		</a>
		<div class="panel">
			<form #resetForm="ngForm">
				<header class="panel__header">
					<h1>{{"ResetPassword" | translate}}</h1>
					<p [ngClass]="{'show-help': showHelp}">{{ "ResetPasswordInstructions" | translate }}</p>
					<a class="help" (click)="toggleHelp()">
						<img width="20" src="../assets/images/icons/button/ic_20x20_btn_help.svg">
					</a>
				</header>

				<section class="panel__body">
					<div class="form-group">
						<apx-input #firstInput
							[settings]="emailSettings"
							[(model)]="email">
						</apx-input>
					</div>
					<div class="form-group">
						<apx-input
							[settings]="newPasswordSettings"
							[(model)]="newPassword"
							(modelChange)="checkPasswordMatch()">
						</apx-input>
					</div>
					<div class="form-group">
						<apx-input
							[settings]="confirmPasswordSettings"
							[(model)]="confirmPassword"
							(modelChange)="checkPasswordMatch()">
						</apx-input>
					</div>

					<div class="error-text text-danger text-center" *ngIf="errorMessage">
						{{ errorMessage }}
					</div>
					<div class="error-text text-danger text-center" *ngIf="!isMatching">
						{{ "DontMatch" | translate }}
					</div>
					<div class="error-text text-danger text-center" *ngIf="newPasswordWhitespaceError || confirmPasswordWhitespaceError">
						{{ "PasswordWhitespaceError" | translate }}
					</div>
				</section>

				<footer class="panel__footer">
					<ng-container *ngIf="submitting">
						<div class="loader small">
							<div class="dot dot-bounce1"></div>
							<div class="dot dot-bounce2"></div>
							<div class="dot dot-bounce3"></div>
						</div>
					</ng-container>
					<button type="button" *ngIf="!submitting" class="btn btn-success" (click)="submit(resetForm)">{{ 'ResetPassword' | translate }}</button>
				</footer>
			</form>
		</div>
	</div>
</div>
