import { HttpClientService } from '../shared/httpclient.service';
import { UrlUtils } from '../shared/url-utils';
import { Observable } from 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

/**
 * Calls the API for reset and returns responses
 */
@Injectable()
export class ResetPasswordService {

	constructor(private httpClientService: HttpClientService) { }

	public resetPassword(email: string, newPassword: string, confirmPassword: string, code: string): Promise<string> {
		return this.httpClientService
			.post(environment.IdsUrl + '/api/account/reset-password?culture=sv-SE', {
				email: email,
				code: code,
				password: newPassword,
				confirmPassword: confirmPassword
			});
	}
}
