import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClientService } from '../shared/httpclient.service';
import { getFullCulture } from '../shared/utils';

export const LOGIN_ENDPOINT = '/api/account';

export const ENDPOINTS = {
	AUTH: `${LOGIN_ENDPOINT}/login`,
	REQUEST_SINGLE_USE_CODE: '/api/singletimecode/requestCode',
	SIGNIN_SINGLE_USE_CODE: '/api/singletimecode/signin'
};

@Injectable()
export class LoginService {
	constructor(private httpClientService: HttpClientService) { }

	/**
	 * The login action, it tries to login a user and if its succeeded a return url is returned
	 * @param user
	 */
	public authenticate(email: string, password: string, culture: string): Promise<any> {
		return this.httpClientService
			.post(environment.IdsUrl + ENDPOINTS.AUTH, {
				email: email,
				password: password,
				defaultUrl: environment.defaultRedirect,
				culture: getFullCulture(culture)
			});
	}

	public requestOneTimeCode(email: string) {
		return this.httpClientService
			.post(environment.IdsUrl + ENDPOINTS.REQUEST_SINGLE_USE_CODE, {
				email: email
			});
	}

	public signInUsingSingleCode(email: string, code: string) {
		return this.httpClientService.post(environment.IdsUrl + ENDPOINTS.SIGNIN_SINGLE_USE_CODE, {
			email: email,
			code: code
		});
	}
}
