import { TranslateService } from '@ngx-translate/core';
import { StringUtils } from '@apirix/ng-apx';

export const SUPPORTED_LANGUAGES = ['sv', 'no', 'en'];
export const DEFAULT_LANGUAGE = 'sv';

/**
 * Helps with translation
 */
export class TranslationHelper {
	constructor(private translateService: TranslateService) { }
	public translatedHelpText() {
		return {
			general: {
				followingNeedsToBeFulfilled: this.translateService.instant(StringUtils.FOLLOWING_NEEDS_TO_BE_FULFILLED, ':')
			},
			email: {
				firstPartCanNotContainSpecialCharacters: this.translateService.instant(
					StringUtils.FIRST_PART_CAN_NOT_CONTAIN_SPECIAL_CHARACTERS_OTHER_THAN, ''
				) + ' "._%+-"',
				mustContainA: this.translateService.instant(StringUtils.MUST_CONTAIN_A, '') + ' "@"',
				domainCanNotContainSpecialCharacters: this.translateService.instant(
					StringUtils.DOMAIN_CAN_NOT_CONTAIN_SPECIAL_CHARACTERS_OTHER_THAN, ''
				) + ' ".-"',
				lastPartMustBeBetween: this.translateService.instant(StringUtils.LAST_PART_MUST_BE_BETWEEN_2_AND_4_CHARACTERS, '')
			},
			phone: {
				between2And15Characters: this.translateService.instant(StringUtils.BETWEEN_2_AND_15_CHARACTERS, ''),
				noAlphabeticalCharacters: this.translateService.instant(StringUtils.NO_ALPHABETICAL_CHARACTERS, '')
			},
			password: {
				aValidPasswordNeedsAtLeast: this.translateService.instant(StringUtils.A_VALID_PASSWORD_NEEDS_AT_LEAST, ':'),
				sixCharacters: this.translateService.instant(StringUtils.SIX_CHARACTERS, ''),
				oneDigit: this.translateService.instant(StringUtils.ONE_DIGIT, ''),
				oneLowercase: this.translateService.instant(StringUtils.ONE_LOWERCASE, ''),
				oneUppercase: this.translateService.instant(StringUtils.ONE_UPPERCASE, ''),
				oneNonAlphanumerical: this.translateService.instant(StringUtils.ONE_NON_ALPHANUMERICAL, '') + ' "$@$!%*?&"'
			},
			zipCode: {
				first: this.translateService.instant(StringUtils.ZIPCODE_FIRST, '') + ' "99 19 99".',
				second: this.translateService.instant(StringUtils.ZIPCODE_SECOND, '') + ' "123 45".',
				third: this.translateService.instant(StringUtils.ZIPCODE_FORMAT, '') + ': "GIR 2AA".',
				fourth: this.translateService.instant(StringUtils.ZIPCODE_FORMAT, '') + ': "A22 2AA" or "A2A 2AA".',
				fifth: this.translateService.instant(StringUtils.ZIPCODE_FORMAT, '') + ': "AA22 2AA", "AA2A 2AA", "AA2 2AA".',
			},
			organizationNumber: {
				title: this.translateService.instant(StringUtils.ORGANIZATION_NUMBER_TITLE, ''),
				limit: this.translateService.instant(StringUtils.ORGANIZATION_NUMBER_LENGTH, '')
			},
			vat: {
				title: this.translateService.instant(StringUtils.ONE_OF_THE_FOLOWING, ''),
				se: this.translateService.instant(StringUtils.VAT_SE, ''),
				gb: this.translateService.instant(StringUtils.VAT_GB, ''),
				no: this.translateService.instant(StringUtils.VAT_NO, ''),
				co: this.translateService.instant(StringUtils.VAT_CO, '')
			},
			customerNumber: {
				title: this.translateService.instant(StringUtils.INVALID_VALUE, ''),
				digitsOnly: this.translateService.instant(StringUtils.ONLY_DIGITS, '')
			}
		};
	}
}

export class InputTranslation {
	clear: string;
	search: string;
	required: string;
	tooLong: string;
	invalidFormat: string;

	constructor(translate: TranslateService) {
		this.clear = translate.instant('Clear');
		this.search = translate.instant('Search');
		this.required = translate.instant('Required');
		this.tooLong = translate.instant('Too long');
		this.invalidFormat = translate.instant('Invalid format');
	}
}
