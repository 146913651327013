import { environment } from '../../environments/environment';
/**
 * Methods that helps with validation
 */
export function logError(message) {
    var optionalParams = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        optionalParams[_i - 1] = arguments[_i];
    }
    if (environment.production) {
        console.error(message, optionalParams);
    }
}
/**
 * Methods that helps with validation
 */
export function logWarning(message) {
    var optionalParams = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        optionalParams[_i - 1] = arguments[_i];
    }
    if (environment.production) {
        console.warn(message, optionalParams);
    }
}
export function getFullCulture(culture) {
    switch (culture) {
        case 'sv':
            return 'sv-SE';
        case 'en':
            return 'en-US';
        case 'no':
            return 'nb-NO';
        default:
            return 'sv-SE';
    }
}
// Given a cookie key `name`, returns the value of
// the cookie or `null`, if the key is not found.
export function getCookie(name) {
    var nameLenPlus = (name.length + 1);
    return document.cookie
        .split(';')
        .map(function (c) { return c.trim(); })
        .filter(function (cookie) {
        return cookie.substring(0, nameLenPlus) === name + "=";
    })
        .map(function (cookie) {
        return decodeURIComponent(cookie.substring(nameLenPlus));
    })[0] || null;
}
