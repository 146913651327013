<div class="row">
	<div class="col-xs-12 col-sm-8 col-md-6 col-lg-4 col-sm-offset-2 col-md-offset-3 col-lg-offset-4">
			<a routerLink="/login" title="Go to the login page" class="login-link">
				{{ "SignIn" | translate }}
			</a>

			<div class="panel">
				<!-- CONFIRM EMAIL -->
				<ng-container *ngIf="!showConfirmation">

					<form #forgotForm="ngForm" (submit)="confirm(true)">
						<header class="panel__header">
							<h1>{{ "ForgotPassword" | translate }}?</h1>
							<p [ngClass]="{'show-help': showHelp}">{{ "ForgotPasswordInstructions" | translate }}</p>
							<a class="help" (click)="toggleHelp()">
								<img width="20" src="../assets/images/icons/button/ic_20x20_btn_help.svg">
							</a>
						</header>

						<section class="panel__body">
							<div class="form-group">
								<apx-input #firstInput [settings]="emailSettings" [(model)]="email"></apx-input>
							</div>
						</section>

						<footer class="panel__footer">
							<ng-container *ngIf="sending">
								<div class="loader small">
									<div class="dot dot-bounce1"></div>
									<div class="dot dot-bounce2"></div>
									<div class="dot dot-bounce3"></div>
								</div>
							</ng-container>
							<button *ngIf="!sending" type="button" class="btn btn-success" (click)="confirm(true)">{{ "ConfirmAccount" | translate }}</button>
						</footer>
					</form>
				</ng-container>
				<!-- END CONFIRM EMAIL -->

				<!-- SHOW CONFIRMATION -->
				<ng-container *ngIf="showConfirmation">
					<form #forgotForm="ngForm" (submit)="confirm(false)">
						<header class="panel__header">
							<h1>{{ "ConfirmAccount" | translate }}</h1>
						</header>
						<section class="panel__body">
							<ng-container *ngIf="sending">
								<div class="loader big">
									<div class="dot dot-bounce1"></div>
									<div class="dot dot-bounce2"></div>
									<div class="dot dot-bounce3"></div>
								</div>
							</ng-container>
							<ng-container *ngIf="!sending">
								<div class="row">
									<p class="col-xs-12 text-center">{{ "ResetPasswordConfirmation" | translate }}</p>
								</div>
							</ng-container>
						</section>
						<footer class="panel__footer">
							<button type="button" class="pull-left btn btn-transparent" (click)="toggleView()">{{ "Back" | translate }}</button>
							<button type="button" class="btn btn-success" (click)="confirm(false)">{{ "SendAgain" | translate }}</button>
						</footer>
					</form>
				</ng-container>
				<!-- END SHOW CONFIRMATION -->
			</div>
	</div>
</div>