import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlUtils } from './shared/url-utils';
import { SUPPORTED_LANGUAGES } from './shared/translation-helper';
import { StringUtils } from './shared/string-utils';

/**
 * The application shell. The header, content and footer is managed and rendered here.
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent {

	message: string;
	showMessage = false;
	ready = true;
	headerLogo = StringUtils.DEFAULT_HEADER_LOGO;
	currentYear: number = new Date().getFullYear();

	constructor(
		private translate: TranslateService) { }

	/**
	 * Changes language if its a valid and supported locale
	 */
	changeLanguage(locale: string) {
		if (locale !== undefined && locale !== null && locale !== '' && SUPPORTED_LANGUAGES.some(o => o.indexOf(locale) >= 0)) {
			// Dont change if its already in use
			if (this.translate.currentLang !== locale) {
				// Append the new lang and reload page
				localStorage.setItem('i18n', locale);
				this.translate.use(locale);
				setTimeout(() => {
					// Wait one second to make the loader atleast display a load for a sec,
					// otherwised its freazed all the time
					UrlUtils.reload();
				}, 1000);
			}
		}
	}

	/**
	 * Displays a message for 10 seconds
	 */
	displayMessage(message: string) {
		this.message = message;
		this.showMessage = true;

		setTimeout(() => {
			this.showMessage = false;
		}, 10000);
	}
}
