<div class="row" *ngIf="shouldRender">
	<form #loginForm="ngForm">
		<section class="panel col-xs-12 col-sm-8 col-md-6 col-lg-4 col-sm-offset-2 col-md-offset-3 col-lg-offset-4">
			<header class="panel__header">
				<h1>{{'SignIn' | translate }}</h1>
				<p [ngClass]="{'show-help': showHelp}">{{'LoginInstructions' | translate }}</p>
				<a class="help" (click)="toggleHelp()">
					<img width="20" src="../assets/images/icons/button/ic_20x20_btn_help.svg">
				</a>
			</header>

			<section class="panel__body"
				[ngClass]="{
					'loading': loading
				}">
				<div class="panel__body__actions">
					<a routerLink="/forgot-password">{{'ForgotPassword' | translate }}?</a>
				</div>
				<div class="form-group">
					<apx-input [settings]="login.emailSettings" [(model)]="login.email"></apx-input>
				</div>
				<div class="form-group" *ngIf="!useOneTimeCode">
					<apx-input [settings]="login.passwordSettings" [(model)]="login.password"></apx-input>
				</div>
				<div class="code-sent-text text-center" *ngIf="!loading && useOneTimeCode && !showRequestWentWrongError">
					{{ 'CodeHasBeenSentToEmail' | translate }}
				</div>
				<div class="form-group" *ngIf="useOneTimeCode" #useOneTimeCodeInput>
					<apx-input [settings]="login.oneTimeCodeSettings" [(model)]="login.oneTimeCode"></apx-input>
				</div>
				<div class="error-text text-danger text-center" *ngIf="showError">
					{{'WrongUsernameOrPassword' | translate }}
				</div>
				<div class="error-text text-danger text-center" *ngIf="showCodeError">
					{{'WrongUsernameOrCode' | translate }}
				</div>
				<div class="error-text text-danger text-center" *ngIf="showRequestWentWrongError">
					{{'SomethingWentWrong' | translate }}
				</div>
			</section>

			<footer class="panel__footer footer"
				[ngClass]="{
					'center': loading
				}">
				<ng-container *ngIf="loading">
					<div class="loader small">
						<div class="dot dot-bounce1"></div>
						<div class="dot dot-bounce2"></div>
						<div class="dot dot-bounce3"></div>
					</div>
				</ng-container>
				<button *ngIf="!loading && !useOneTimeCode"
					class="btn btn-outline"
					type="button"
					(click)="useCode()">
					{{'UseOneTimeCode' | translate }}
				</button>
				<div class="button-wrapper">
					<button *ngIf="!loading && useOneTimeCode"
						class="btn btn-outline back"
						type="button"
						(click)="goBack()">
						{{'Back' | translate }}
					</button>
					<button class="send-again" *ngIf="!loading && useOneTimeCode && canRequestCode"
						type="button"
						(click)="requestCode()">
						{{'SendCodeAgain' | translate }}
					</button>
					<div *ngIf="useOneTimeCode && !canRequestCode"
						id="countdown"
						class="countdown">
						{{ 'CanSendAgainIn' | translate }} {{ secondsLeft }}
					</div>
				</div>
				<button *ngIf="!loading" class="btn btn-success" type="submit" (click)="authenticate(loginForm)">{{'SignIn' | translate }}</button>
			</footer>
		</section>
	</form>
</div>
<div class="redirect-text" *ngIf="!shouldRender">
	<ng-container>
		<div class="loader small">
			<div class="dot dot-bounce1"></div>
			<div class="dot dot-bounce2"></div>
			<div class="dot dot-bounce3"></div>
		</div>
	</ng-container>
	{{ getRedirectText() }}
</div>