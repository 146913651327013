var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApxInputComponent } from '@apirix/ng-apx';
import { TranslateService } from '@ngx-translate/core';
import { ToastsManager } from 'ng2-toastr/src/toast-manager';
import { TranslationHelper } from '../shared/translation-helper';
import { UrlUtils } from '../shared/url-utils';
import { logError, getCookie } from '../shared/utils';
import { ValidationHelper } from '../shared/validation-helper';
import { Login } from './login';
import { LoginService } from './login.service';
import { environment } from '../../environments/environment';
/**
 * Here the user can login
 */
var LoginComponent = (function () {
    function LoginComponent(translateService, loginService, toastr, router, cdRef) {
        this.translateService = translateService;
        this.loginService = loginService;
        this.toastr = toastr;
        this.router = router;
        this.cdRef = cdRef;
        this.loading = false;
        this.shouldRender = false;
        this.showHelp = false;
        this.showError = false;
        this.showCodeError = false;
        this.showRequestWentWrongError = false;
        this.useOneTimeCode = false;
        this.canRequestCode = true;
        this.secondsLeft = 10;
        this.validationHelper = new ValidationHelper(this.toastr);
    }
    LoginComponent.prototype.ngOnInit = function () {
        if (this.isLoggedIn()) {
            UrlUtils.navigate(environment.defaultRedirect);
        }
        else {
            this.shouldRender = true;
        }
        this.login = new Login(new TranslationHelper(this.translateService).translatedHelpText(), this.translateService);
        // Toggle the first input in login form
        if (this.firstInput) {
            this.firstInput.cdRef.markForCheck();
            this.cdRef.detectChanges();
            if (this.firstInput.input && this.firstInput.input.nativeElement) {
                this.firstInput.toggleFocus(true);
                this.firstInput.input.nativeElement.focus();
                this.firstInput.cdRef.markForCheck();
            }
        }
        if (this.useOneTimeCodeInput) {
            this.useOneTimeCodeInput.settings.required = this.useOneTimeCode;
        }
    };
    LoginComponent.prototype.isLoggedIn = function () {
        return !!getCookie('idsrv.session');
    };
    /**
     * Toggles help panel
     */
    LoginComponent.prototype.toggleHelp = function () {
        this.showHelp = !this.showHelp;
    };
    /**
     * Authenticates a user by passing email and password
     */
    LoginComponent.prototype.authenticate = function (form) {
        return __awaiter(this, void 0, void 0, function () {
            var redirectUrl, errorReponse_1, response, latestBustedVersion, versionToBust, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.cdRef.detectChanges();
                        if (this.isLoggedIn()) {
                            this.shouldRender = false;
                            UrlUtils.navigate(environment.defaultRedirect);
                        }
                        if (!this.validationHelper.validateForm(form, true, this.formControls)) return [3 /*break*/, 12];
                        this.showError = false;
                        this.showCodeError = false;
                        this.loading = true;
                        redirectUrl = UrlUtils.getParam('redirect_uri', true);
                        if (redirectUrl === '' || redirectUrl !== '') {
                            redirectUrl = environment.defaultRedirect;
                        }
                        if (!this.useOneTimeCode) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.loginService.signInUsingSingleCode(this.login.email, this.login.oneTimeCode)];
                    case 2:
                        _a.sent();
                        UrlUtils.navigate(redirectUrl);
                        return [3 /*break*/, 4];
                    case 3:
                        errorReponse_1 = _a.sent();
                        if (errorReponse_1.status === 403) {
                            this.router.navigateByUrl('/lockedout');
                        }
                        else if (errorReponse_1.status === 401) {
                            this.showCodeError = true;
                            this.loading = false;
                        }
                        else {
                            this.showCodeError = true;
                            this.loading = false;
                            logError(errorReponse_1);
                        }
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 12];
                    case 5:
                        _a.trys.push([5, 11, , 12]);
                        return [4 /*yield*/, this.loginService.authenticate(this.login.email, this.login.password, this.translateService.currentLang)];
                    case 6:
                        response = _a.sent();
                        if (!response) return [3 /*break*/, 9];
                        latestBustedVersion = localStorage.getItem("latest-busted-version");
                        versionToBust = "1.28.3";
                        if (!(latestBustedVersion !== versionToBust)) return [3 /*break*/, 8];
                        return [4 /*yield*/, fetch("https://admin.inquinova.com/callback", {
                                method: "GET",
                                cache: "no-cache",
                            }).catch(function (e) { return console.error(e); })];
                    case 7:
                        _a.sent();
                        localStorage.setItem("latest-busted-version", versionToBust);
                        _a.label = 8;
                    case 8:
                        UrlUtils.navigate(redirectUrl);
                        return [3 /*break*/, 10];
                    case 9:
                        this.showError = true;
                        this.loading = false;
                        _a.label = 10;
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        err_1 = _a.sent();
                        if (err_1.status === 403) {
                            this.router.navigateByUrl('/lockedout');
                        }
                        else if (err_1.status === 401) {
                            this.showError = true;
                            this.loading = false;
                        }
                        else {
                            this.showError = true;
                            this.loading = false;
                            logError(err_1);
                        }
                        return [3 /*break*/, 12];
                    case 12: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.useCode = function () {
        this.showError = false;
        this.showCodeError = false;
        this.firstInput.settings.validation.submitted = true;
        this.cdRef.detectChanges();
        if (this.firstInput.settings.validation.valid) {
            this.useOneTimeCode = true;
            this.requestCode();
        }
    };
    LoginComponent.prototype.goBack = function () {
        var _this = this;
        this.login.oneTimeCodeSettings.required = false;
        setTimeout(function () {
            _this.login.oneTimeCode = null;
            _this.useOneTimeCode = false;
        });
    };
    LoginComponent.prototype.requestCode = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var timer_1, errorResponse_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.canRequestCode) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.loading = true;
                        return [4 /*yield*/, this.loginService.requestOneTimeCode(this.login.email)];
                    case 2:
                        _a.sent();
                        this.loading = false;
                        this.showRequestWentWrongError = false;
                        this.canRequestCode = false;
                        timer_1 = setInterval(function () {
                            if (_this.secondsLeft <= 0) {
                                clearInterval(timer_1);
                                _this.canRequestCode = true;
                                _this.secondsLeft = 10;
                            }
                            _this.secondsLeft -= 1;
                        }, 1000);
                        return [3 /*break*/, 4];
                    case 3:
                        errorResponse_1 = _a.sent();
                        this.showRequestWentWrongError = true;
                        this.loading = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.getRedirectText = function () {
        return this.translateService.instant('RedirectText') + " " + environment.defaultRedirect;
    };
    return LoginComponent;
}());
export { LoginComponent };
