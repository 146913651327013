import { UrlUtils } from './url-utils';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

/**
 * A generic service that wraps all API calls.
 * Appends the authorization header to every request.
 */
@Injectable()
export class HttpClientService {

	constructor(private http: HttpClient) { }

	/**
	 * Performs POST requests
	 */
	public post(url: string, data: any = {}, options: any = {}): Promise<any> {
		options.headers = UrlUtils.httpHeaders;
		return this.http.post(url, data, options).toPromise();
	}
}
