import { Component, forwardRef, HostListener, OnInit, ViewChild, ViewChildren, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
	ApxInputComponent,
	IconSettings,
	InputSettings,
	MediaUtils,
	StringUtils,
	ValidationMessages,
	Variant,
	HelpSettings,
	Pattern,
} from '@apirix/ng-apx';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordService } from '../reset-password/reset-password.service';
import { UrlUtils } from '../shared/url-utils';
import { ValidationHelper } from '../shared/validation-helper';
import { ToastsManager } from 'ng2-toastr';
import { InputTranslation, TranslationHelper } from '../shared/translation-helper';
import { ChangeDetectorRef } from '@angular/core';
import { RegexUtils } from '../shared/regex-utils';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { LoginService } from '../login/login.service';
import { Login } from '../login/login';

/**
 * When a user has got the forgot-password link in a e-mail they come to this component.
 * Here the user gets to choose a new password.
 */
@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit {
	errorMessage: any;
	translationHelper: TranslationHelper;
	showHelp: boolean;
	validationHelper: ValidationHelper;
	media = MediaUtils;
	submitting = false;
	submitted = false;
	isMatching = true;
	code: string;

	newPassword: string;
	confirmPassword: string;
	email: string;

	newPasswordSettings: InputSettings;
	confirmPasswordSettings: InputSettings;
	emailSettings: InputSettings;

	newPasswordWhitespaceError: boolean = false;
	confirmPasswordWhitespaceError: boolean = false;

	@ViewChild('firstInput') firstInput: ApxInputComponent;
	@ViewChild('passwordForm') passwordForm: NgForm;
	@ViewChild('resetForm') form: NgForm;
	@ViewChildren(ApxInputComponent) formControls: ApxInputComponent[];

	constructor(
		private resetPasswordService: ResetPasswordService,
		private toastr: ToastsManager,
		private translate: TranslateService,
		private cdRef: ChangeDetectorRef,
		private router: Router,
		private loginService: LoginService,
		@Inject(forwardRef(() => AppComponent)) public app: AppComponent
	) {
		this.code = UrlUtils.getParam('code', false);

		if (this.code === '') {
			this.router.navigateByUrl('/login');
		}

		this.validationHelper = new ValidationHelper(toastr);
		const inputTranslations = new ValidationMessages(new InputTranslation(translate));
		this.translationHelper = new TranslationHelper(translate);
		const helpText = this.translationHelper.translatedHelpText();

		this.emailSettings = new InputSettings({
			id: 'email',
			brand: StringUtils.INPUT,
			type: StringUtils.TEXT,
			required: true,
			pattern: RegexUtils.EMAIL,
			placeholder: translate.instant('Email'),
			label: translate.instant('Email'),
			help: new HelpSettings(
				MediaUtils.BTN_HELP_ERROR,
				helpText.general.followingNeedsToBeFulfilled,
				[
					new Pattern(RegexUtils.EMAIL_FIRST, helpText.email.firstPartCanNotContainSpecialCharacters),
					new Pattern(RegexUtils.EMAIL_AT, helpText.email.mustContainA),
					new Pattern(RegexUtils.EMAIL_DOMAIN, helpText.email.domainCanNotContainSpecialCharacters),
					new Pattern(RegexUtils.EMAIL_LAST, helpText.email.lastPartMustBeBetween)
				]),
			icon: new IconSettings([
				new Variant('icon', MediaUtils.INPUT_MAIL),
				new Variant('icon--error', MediaUtils.INPUT_MAIL_ERROR),
				new Variant('icon--focused', MediaUtils.INPUT_MAIL_FOCUSED)
			], 24, 24),
			messages: inputTranslations
		}, true);
		this.emailSettings.messages.invalidFormat = this.translate.instant('InvalidEmail');

		this.newPasswordSettings = new InputSettings({
			id: 'newPassword',
			brand: StringUtils.INPUT,
			type: StringUtils.PASSWORD,
			required: true,
			minLength: 6,
			pattern: RegexUtils.PASSWORD,
			placeholder: translate.instant('NewPassword'),
			label: translate.instant('NewPassword'),
			icon: new IconSettings([
				new Variant('icon', MediaUtils.INPUT_PASS_NEW),
				new Variant('icon--error', MediaUtils.INPUT_PASS_NEW_ERROR),
				new Variant('icon--focused', MediaUtils.INPUT_PASS_NEW_FOCUSED)
			], 24, 24),
			help: new HelpSettings(
				MediaUtils.BTN_HELP_ERROR,
				helpText.password.aValidPasswordNeedsAtLeast,
				[
					new Pattern(RegexUtils.PASSWORD_LENGTH, helpText.password.sixCharacters),
					new Pattern(RegexUtils.PASSWORD_DIGIT, helpText.password.oneDigit),
					new Pattern(RegexUtils.PASSWORD_LOWER, helpText.password.oneLowercase),
					new Pattern(RegexUtils.PASSWORD_UPPER, helpText.password.oneUppercase),
					new Pattern(RegexUtils.PASSWORD_SPECIAL, helpText.password.oneNonAlphanumerical)
				]),
			messages: inputTranslations
		}, true);
		this.newPasswordSettings.messages.invalidFormat = this.translate.instant('InvalidPassword');

		this.confirmPasswordSettings = new InputSettings({
			id: 'confirmPassword',
			brand: StringUtils.INPUT,
			type: StringUtils.PASSWORD,
			minLength: 6,
			required: true,
			pattern: RegexUtils.PASSWORD,
			placeholder: translate.instant('ConfirmPassword'),
			label: translate.instant('ConfirmPassword'),
			icon: new IconSettings([
				new Variant('icon', MediaUtils.INPUT_PASS_CONFIRM),
				new Variant('icon--error', MediaUtils.INPUT_PASS_CONFIRM_ERROR),
				new Variant('icon--focused', MediaUtils.INPUT_PASS_CONFIRM_FOCUSED)
			], 24, 24),
			help: new HelpSettings(
				MediaUtils.BTN_HELP_ERROR,
				helpText.password.aValidPasswordNeedsAtLeast,
				[
					new Pattern(RegexUtils.PASSWORD_LENGTH, helpText.password.sixCharacters),
					new Pattern(RegexUtils.PASSWORD_DIGIT, helpText.password.oneDigit),
					new Pattern(RegexUtils.PASSWORD_LOWER, helpText.password.oneLowercase),
					new Pattern(RegexUtils.PASSWORD_UPPER, helpText.password.oneUppercase),
					new Pattern(RegexUtils.PASSWORD_SPECIAL, helpText.password.oneNonAlphanumerical)
				]),
			messages: inputTranslations
		}, true);
		this.confirmPasswordSettings.messages.invalidFormat = this.translate.instant('InvalidPassword');
	}

	ngOnInit() {
		this.newPassword = '';
		this.confirmPassword = '';
		this.email = '';

		// Toggle the first input in login form
		if (this.firstInput) {
			this.firstInput.cdRef.markForCheck();
			this.cdRef.detectChanges();
			if (this.firstInput.input && this.firstInput.input.nativeElement) {
				this.firstInput.toggleFocus(true);
				this.firstInput.input.nativeElement.focus();
				this.firstInput.cdRef.markForCheck();
			}
		}
	}

	toggleHelp() {
		this.showHelp = !this.showHelp;
	}

	submit(form: NgForm) {
		this.cdRef.detectChanges();
		this.submitted = true;

		if (!this.newPasswordWhitespaceError && !this.confirmPasswordWhitespaceError && this.validationHelper.validateForm(form, true, this.formControls) && this.isMatching) {
			this.validationHelper.clearServerValidation(this.formControls);
			this.changePassword();
		}
	}

	changePassword() {
		this.submitting = true;

		this.resetPasswordService.resetPassword(this.email, this.newPassword.trim(), this.confirmPassword.trim(), this.code)
			.then((resetResponse: any) => {
				this.loginService.authenticate(this.email, this.newPassword, this.translate.currentLang)
					.then(loginResponse => {
						if (loginResponse) {
							// The user is logged in, redirect to the returnUrl
							UrlUtils.navigate('');
						} else {
							// No returnUrl returned from server, redirect to login page
							this.submitting = false;
							this.app.displayMessage(resetResponse.successMessage);
							this.router.navigateByUrl('/login');
						}
					}).catch(err => {
						// Couldnt login the user, nav to login or lockedout depending on status
						this.submitting = false;
						if (err.status === 403) {
							this.router.navigateByUrl('/lockedout');
						} else if (err.status === 401) {
							this.router.navigateByUrl('/login');
						} else {
							this.router.navigateByUrl('/login');
						}
					});
			})
			.catch(error => {
				this.submitting = false;
				if (error.error
					&& error.error.errors
					&& error.error.errors.GenericError
					&& error.error.errors.GenericError.length > 0) {
					this.errorMessage = error.error.errors.GenericError[0];
				}
			});
	}

	checkPasswordMatch() {
		this.newPasswordWhitespaceError = this.newPassword.startsWith(" ") || this.newPassword.endsWith(" ");
		this.confirmPasswordWhitespaceError = this.confirmPassword.startsWith(" ") || this.confirmPassword.endsWith(" ");

		if (this.submitted) {
			this.cdRef.detectChanges();
			this.errorMessage = undefined;
			this.isMatching = this.newPassword === this.confirmPassword;
		}
	}

	resetFields() {
		this.newPassword = '';
		this.confirmPassword = '';
		this.email = '';
		this.validationHelper.clearValidation(this.formControls);
	}

	/**
	 * Navigation with keys
	 * @param event
	 */
	@HostListener('window:keydown', ['$event']) onKeyDown(event) {
		switch (event.keyCode) {
			case 13:
				// Right key
				this.submit(this.form);
				break;
			default:
				break;
		}
	}
}
