import { NgModule } from '@angular/core';
import { ApxInputModule } from '@apirix/ng-apx';

/**
 * Contains all modules that is shared between all modules
 */
@NgModule({
	imports: [
		ApxInputModule.forRoot({}),
	],
	declarations: [
	],
	providers: [
	],
	exports: [
		ApxInputModule
	]
})
export class SharedModule { }
