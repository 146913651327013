import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

/**
 * Our 404 page, it only redirects to the main page at the moment
 */
@Component({
	selector: 'app-page-not-found',
	template: ''
})
export class PageNotFoundComponent implements OnInit {

	constructor(private router: Router) { }

	ngOnInit() {
		this.router.navigate(['login']);
	}

}
