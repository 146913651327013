export class RegexUtils {
	public static EMAIL = /^\s*\S+@\S+\s*$/i;
	public static EMAIL_FIRST = /^[a-zåäöA-ZÅÄÖ0-9._%+-]+@.*/i;
	public static EMAIL_AT = /@/;
	public static EMAIL_DOMAIN = /@[a-zåäöA-ZÅÄÖ0-9.-]+.\./i;
	public static EMAIL_LAST = /\.[a-zåäöA-ZÅÄÖ]{2,}$/i;

	public static PHONE = /^[^a-zåäöA-ZÅÄÖ][0-9-+(). ]{2,15}$/;
	public static PHONE_NO_LETTERS = /^[^a-zåäöA-ZÅÄÖ][0-9-+(). ]{0,}$/;
	public static PHONE_LENGTH = /^.{3,20}/;

	public static PASSWORD = /^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])(?=.*\d)(?=.*[$@$!%*?&])[A-ZÅÄÖa-zåäö\d$@$!%*?&]{6,}/;
	public static PASSWORD_DIGIT = /[\d]/;
	public static PASSWORD_LOWER = /[a-zåäö]/;
	public static PASSWORD_UPPER = /[A-ZÅÄÖ]/;
	public static PASSWORD_SPECIAL = /[$@$!%*?&]/;
	public static PASSWORD_LENGTH = /.{6,}/;

	public static ONE_TIME_CODE = /[a-zA-Z]{2}[1-9]{4}/;

	public static ORGANIZATION_NUMBER = /^(\d{1})(\d{5})[-]?(\d{4})$/;
	public static ORGANIZATION_NUMBER_LENGTH = /^(.{1})(.{5})(\-?)(.{4})$/;

	public static VAT = /^((SE)?[0-9]{12})$|^((GB)?([0-9]{9}([0-9]{3})?$|^[A-Z]{2}[0-9]{3}))$|^((NO)?[0-9]{9}(MVA))$|^((CO)?[0-9]{10})$/;
	public static VAT_SE = /^((SE)?[0-9]{12})$/;
	public static VAT_GB = /^((GB)?([0-9]{9}([0-9]{3})?$|^[A-Z]{2}[0-9]{3}))$/;
	public static VAT_NO = /^((NO)?[0-9]{9}(MVA))$/;
	public static VAT_CO = /^((CO)?[0-9]{10})$/;

	public static ZIPCODE = /^\d{2}[ ]?[0-8][0-9][ ]?\d{2}$|^(\d{3}[ ]?\d{2})$|^(\d{4})$|^(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{2})$/;
	public static ZIPCODE_FIRST = /^\d{2}[ ]?[0-8][0-9][ ]?\d{2}$/;
	public static ZIPCODE_SECOND = /^\d{3}[ ]?\d{2}$/;
	public static ZIPCODE_THIRD = /^GIR[ ]??(\d[A-Z]{2})$/;
	public static ZIPCODE_FOURTH = /^[A-Z]\d[A-Z\d][ ]??(\d[A-Z]{2})$/;
	public static ZIPCODE_FIFTH = /^[A-Z]{2}\d[A-Z\d]??[ ]??(\d[A-Z]{2})$/;

	public static LONGITUDE = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
	public static LATITUDE = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;

	public static CUSTOMER_NUMBER = /\d*/;
}
