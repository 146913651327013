import { ApxInputComponent, ValidationMessages } from '@apirix/ng-apx';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import {
	HelpSettings,
	IconSettings,
	InputSettings,
	MediaUtils,
	Pattern,
	StringUtils,
	ValidationUtils,
	Variant,
} from '@apirix/ng-apx';

import { ForgotPasswordService } from './forgot-password.service';
import { ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RegexUtils } from '../shared/regex-utils';
import { TranslateService } from '@ngx-translate/core';
import { InputTranslation, TranslationHelper } from '../shared/translation-helper';

/**
 * When a user has forgotten their password they have to enter their email and they will
 * get an recover-email that will navigate them to the recover component.
 */
@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {
	showHelp: boolean;
	translationHelper: any;
	validationHelper: ValidationUtils;
	sending = false;
	email = '';
	showConfirmation = false;
	@ViewChild('firstInput') firstInput: ApxInputComponent;
	@ViewChildren(ApxInputComponent) formControls: ApxInputComponent[];
	@ViewChild('forgotForm') form: NgForm;

	emailSettings: InputSettings;

	constructor(
		private forgotPasswordService: ForgotPasswordService,
		private router: Router,
		private cdRef: ChangeDetectorRef,
		private translate: TranslateService
	) {
		this.validationHelper = new ValidationUtils(null);
		const inputTranslations = new ValidationMessages(new InputTranslation(translate));
		this.translationHelper = new TranslationHelper(translate);
		const helpText = this.translationHelper.translatedHelpText();
		this.emailSettings = new InputSettings({
			brand: StringUtils.INPUT,
			type: StringUtils.TEXT,
			required: true,
			pattern: RegexUtils.EMAIL,
			placeholder: this.translate.instant('Email'),
			label: this.translate.instant('Email'),
			help: new HelpSettings(
				MediaUtils.BTN_HELP_ERROR,
				helpText.general.followingNeedsToBeFulfilled,
				[
					new Pattern(RegexUtils.EMAIL_FIRST, helpText.email.firstPartCanNotContainSpecialCharacters),
					new Pattern(RegexUtils.EMAIL_AT, helpText.email.mustContainA),
					new Pattern(RegexUtils.EMAIL_DOMAIN, helpText.email.domainCanNotContainSpecialCharacters),
					new Pattern(RegexUtils.EMAIL_LAST, helpText.email.lastPartMustBeBetween)
				]),
			icon: new IconSettings([
				new Variant('icon', MediaUtils.INPUT_MAIL),
				new Variant('icon--error', MediaUtils.INPUT_MAIL_ERROR),
				new Variant('icon--focused', MediaUtils.INPUT_MAIL_FOCUSED)
			], 24, 24),
			messages: inputTranslations
		}, true);
		this.emailSettings.messages.invalidFormat = this.translate.instant('InvalidEmail');
	}

	ngOnInit() {
		// Toggle the first input in login form
		if (this.firstInput) {
			this.firstInput.cdRef.markForCheck();
			this.cdRef.detectChanges();
			if (this.firstInput.input && this.firstInput.input.nativeElement) {
				this.firstInput.toggleFocus(true);
				this.firstInput.input.nativeElement.focus();
				this.firstInput.cdRef.markForCheck();
			}
		}
	}

	/**
	 * Toggles help panel
	 */
	toggleHelp() {
		this.showHelp = !this.showHelp;
	}

	/**
	 * Submits the forgot-password form
	 */
	confirm(toggle: boolean) {
		this.cdRef.detectChanges();
		if (this.validationHelper.validateForm(this.form, true, this.formControls)) {
			this.sending = true;
			this.validationHelper.clearServerValidation(this.formControls);
			this.forgotPasswordService.sendConfirm(this.email.trim()).then(_ => {
				this.sending = false;
				if (toggle) {
					this.toggleView();
				}
			}).catch(error => {
				this.sending = false;
			});
		}
	}

	/**
	 * Toggles the two different views.
	 */
	toggleView() {
		this.showConfirmation = !this.showConfirmation;
		if (!this.showConfirmation) {
			this.emailSettings.validation.submitted = false;
		}
	}
}
