import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClientService } from '../shared/httpclient.service';
import { UrlUtils } from '../shared/url-utils';

/**
 * Calls the API when a user needs to recover their password.
 */
@Injectable()
export class ForgotPasswordService {

	constructor(private httpClientService: HttpClientService) { }

	/**
	 * Posts the form that triggers a mail
	 */
	public sendConfirm(email: string): Promise<string> {
		return this.httpClientService
			.post(environment.IdsUrl + '/api/account/forgot-password?culture=sv-SE', {
				email: email
			});
	}
}
