import { Component, OnInit, ViewEncapsulation } from '@angular/core';

/**
 * When a user is locked out, they are navigated here.
 */
@Component({
	selector: 'app-lockedout',
	templateUrl: './lockedout.component.html',
})
export class LockedoutComponent {
}
