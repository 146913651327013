import {
	IconSettings,
	ValidationMessages,
	InputSettings,
	MediaUtils,
	StringUtils,
	Variant
} from '@apirix/ng-apx';
import { TranslateService } from '@ngx-translate/core';

import { InputTranslation } from '../shared/translation-helper';
import { RegexUtils } from '../shared/regex-utils';

export class Login {

	public email = '';
	public password = '';
	public oneTimeCode = '';
	emailSettings: InputSettings;
	passwordSettings: InputSettings;
	oneTimeCodeSettings: InputSettings;

	constructor(private helpText, private translate: TranslateService) {
		const inputTranslations = new ValidationMessages(new InputTranslation(translate));
		this.emailSettings = new InputSettings({
			id: 'email',
			brand: StringUtils.INPUT,
			type: StringUtils.TEXT,
			required: true,
			placeholder: this.translate.instant('Email'),
			label: this.translate.instant('Email'),
			maxLength: 250,
			pattern: RegexUtils.EMAIL,
			icon: new IconSettings([
				new Variant('icon', MediaUtils.INPUT_MAIL),
				new Variant('icon--error', MediaUtils.INPUT_MAIL_ERROR),
				new Variant('icon--focused', MediaUtils.INPUT_MAIL_FOCUSED)
			], 24, 24),
			messages: inputTranslations
		}, true);

		this.emailSettings.messages.invalidFormat = this.translate.instant('InvalidEmail');

		this.passwordSettings = new InputSettings({
			id: 'password',
			brand: StringUtils.INPUT,
			type: StringUtils.PASSWORD,
			required: true,
			minLength: 6,
			placeholder: this.translate.instant('Password'),
			label: this.translate.instant('Password'),
			icon: new IconSettings([
				new Variant('icon', MediaUtils.INPUT_PASS_DEFAULT),
				new Variant('icon--error', MediaUtils.INPUT_PASS_DEFAULT_ERROR),
				new Variant('icon--focused', MediaUtils.INPUT_PASS_DEFAULT_FOCUSED)
			], 24, 24),
			messages: inputTranslations
		}, true);

		this.oneTimeCodeSettings = new InputSettings({
			id: 'oneTimeCode',
			brand: StringUtils.INPUT,
			type: StringUtils.TEXT,
			required: true,
			minLength: 6,
			maxLength: 6,
			placeholder: this.translate.instant('OneTimeCode'),
			pattern: RegexUtils.ONE_TIME_CODE,
			icon: new IconSettings([
				new Variant('icon', MediaUtils.INPUT_PASS_DEFAULT),
				new Variant('icon--error', MediaUtils.INPUT_PASS_DEFAULT_ERROR),
				new Variant('icon--focused', MediaUtils.INPUT_PASS_DEFAULT_FOCUSED)
			], 24, 24),
			messages: inputTranslations
		}, true);

		this.oneTimeCodeSettings.messages.invalidFormat = this.translate.instant('InvalidCode');
	}
}
